import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const APIURL =
  "https://irkhexa2uc.execute-api.eu-central-1.amazonaws.com/api";

export async function getSubscriptionUrl(token: string) {
  console.log("Fetching sub button", token);
  const response = await fetch(APIURL + "/portal", {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  return await response.json();
}

export async function getCustomerProfile(token: string) {
  if (!token) return null;
  return fetchSettings(token);
}

async function fetchSettings(token: string) {
  const response = await fetch(APIURL + "/settings", {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  return await response.json();
}
